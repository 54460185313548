import React from "react";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from 'react-router-dom';
import Header from "./Header";
import GamesPage from "../pages/GamesPage";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import RulesPage from "../pages/RulesPage";
import AboutPage from "../pages/AboutPage";
import LeaderboardPage from "../pages/LeaderboardPage";
import AccountPage from "../pages/AccountPage";
import AdminPage from "../pages/AdminPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import {useFetchSingleUserQuery} from "../store";
import {connect} from "react-redux";

function App() {

    const ProtectedRoute = ({ user }) => {
        return user?.email ? <Outlet /> : <Navigate to="/login" replace />;
    };

    const AdminRoute = ({user}) => {
        if (user) {
            if (user.email === "bakkenlab@gmail.com" || user.email === "kadlec.nicholas@gmail.com") {
                return <Outlet/>;
            } else {
                return <Navigate to="/currentGames" replace />;
            }
        } else {
            return <Navigate to="/login" replace />;
        }
    }

    const AnonymousRoute = ({ user }) => {
        return <Outlet />;
    };

    const {data:user, isFetching:isFetchingUser, error: errorUser} = useFetchSingleUserQuery();

    if (isFetchingUser) {
        return <div></div>
    } else if (errorUser) {
        return <div>Error getting user</div>
    } else {
        return (
            <div className="App">
                <div className="bg-faded">
                    <BrowserRouter>
                        <Header user={user}/>
                        <Routes>
                            <Route path="/" element={<Navigate to="/login" replace />} />

                            <Route element={<AnonymousRoute user={user} />}>
                                <Route exact path="/login" element={<LoginPage user={user}/>} />
                                <Route exact path='/signup' element={<SignupPage/>}/>
                                <Route exact path='/rules' element={<RulesPage/>}/>
                                <Route exact path='/about' element={<AboutPage/>}/>
                                <Route exact path='/forgotPassword' element={<ForgotPasswordPage/>}/>
                                <Route exact path='/resetPassword' element={<ResetPasswordPage/>}/>
                            </Route>

                            <Route element={<ProtectedRoute user={user} />}>
                                <Route exact path="/currentGames" element={<GamesPage user={user} />} />
                                <Route exact path="/leaderboard" element={<LeaderboardPage currentUser={user}/>} />
                                <Route exact path="/account" element={<AccountPage user={user}/> }/>
                            </Route>

                            <Route element={<AdminRoute user={user} />}>
                                <Route exact path="/admin" element={<AdminPage/> }/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </div>
            </div>
        );
    }

}
export default connect(null)(App);