import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useState} from "react";
import {useChangePasswordMutation, useEditUserMutation} from "../store";

function AccountPage({user}) {

    const [showEditForm, setShowEditForm] = useState(false);
    const [editUserMut, editUserResults] = useEditUserMutation();
    const [editError, setEditError] = useState({});
    const [editSuccess, setEditSuccess] = useState("");

    const [changePasswordMut, changePasswordMutResults] = useChangePasswordMutation();
    const [showChangePass, setShowChangePass] = useState(false);

    const submitEditForm = async (event) => {
        event.preventDefault();

        if (!event.target.firstName.value) {
            setEditError({message:"First Name is Required"});
        } else if (!event.target.lastName.value) {
            setEditError({message:"Last Name is Required"});
        } else if (!event.target.leagueName.value) {
            setEditError({message:"Team Name is Required"});
        } else {
            try {
                const user = {
                    firstName: event.target.firstName.value,
                    lastName: event.target.lastName.value,
                    leagueName: event.target.leagueName.value
                }
                await editUserMut(user).unwrap().then();
            } catch (error) {
                setEditError({message:error.data.mess})
            }
        }
    }

    const submitPasswordForm = async (event) => {
        event.preventDefault();
        if (!event.target.currentPassword.value) {
            setEditError({message:"Current Password is required"});
        } else if (!event.target.newPassword.value) {
            setEditError({message:"New Password is required"});
        } else if (!event.target.newPassword2.value) {
            setEditError({message:"Confirm Password is required"});
        } else if (event.target.newPassword.value !== event.target.newPassword2.value) {
            setEditError({message:"New Passwords do not match!"});
        } else if (event.target.currentPassword.value === event.target.newPassword.value) {
            setEditError({message:"Current and New Passwords match. Please enter a new password."});
        } else {
            const user = {
                currentPassword: event.target.currentPassword.value,
                newPassword: event.target.newPassword.value,
            }
            try {
                const changedPassRes = await changePasswordMut(user).unwrap().then();
                setEditSuccess(changedPassRes.mess);
                hideForm();
            } catch (error) {
                setEditError({message:error.data.mess})
            }
        }
    }

    function showForm() {
        setShowEditForm(true);
    }
    function hideForm() {
        setShowEditForm(false);
        setShowChangePass(false);
    }
    const showPasswordForm = () => {
        setShowChangePass(true);
    }

    let editContent;
    if (Object.keys(editError).length !== 0) {
        editContent = (<div>
            <span className="text-danger">{editError.message}</span>
        </div>);
    }

    let editSuccessContent;
    if (editSuccess) {
        editSuccessContent = (<div>
            <span className="text-success">{editSuccess}</span>
        </div>);
    }

    let content;
    if (showEditForm) {
        content = (
            <div className='mt-3 d-flex align-items-center flex-column'>
                <div className='p-2'>
                    {editContent}
                    <div className='mt-3 d-flex flex-row'>
                        <h3>My Account</h3>
                    </div>
                    <Form onSubmit={submitEditForm}>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control type="email" disabled defaultValue={user.email} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="firstName">
                            <Form.Label>First Name:</Form.Label>
                            <Form.Control type="text" defaultValue={user.firstName} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="lastName">
                            <Form.Label>Last Name:</Form.Label>
                            <Form.Control type="text" defaultValue={user.lastName} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="leagueName">
                            <Form.Label>Team Name:</Form.Label>
                            <Form.Control type="text" defaultValue={user.leagueName} />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                        <Button variant="secondary" className="ms-2" onClick={hideForm}>
                            Cancel
                        </Button>
                    </Form>
                </div>
            </div>
        )
    } else if (showChangePass) {
        content = (
            <div className='mt-3 d-flex align-items-center flex-column'>
                <div className='p-2'>
                    {editSuccessContent}
                    {editContent}
                    <div className='mt-3 d-flex flex-row'>
                        <h3>My Account</h3>
                    </div>
                    <Form onSubmit={submitPasswordForm}>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control type="email" disabled defaultValue={user.email} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="currentPassword">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control type="password" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="newPassword">
                            <Form.Label>New Password:</Form.Label>
                            <Form.Control type="password"/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="newPassword2">
                            <Form.Label>Confirm Password: </Form.Label>
                            <Form.Control type="password" />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                        <Button variant="secondary" className="ms-2" onClick={hideForm}>
                            Cancel
                        </Button>
                    </Form>
                </div>
            </div>
        )
    } else {
        content = (
            <div className='mt-3 d-flex align-items-center flex-column'>
                <div className='p-2'>
                    {editSuccessContent}
                    <div className='mt-3 d-flex flex-row'>
                        <h3>My Account</h3>
                        <Button className='ms-3' variant="primary" onClick={showForm}>Edit Info</Button>
                    </div>
                    <p className="text-start mt-3">Email: {user.email}</p>
                    <p className="text-start">First Name: {user.firstName}</p>
                    <p className="text-start">Last Name: {user.lastName}</p>
                    <p className="text-start">Team Name: {user.leagueName}</p>
                    <Button className='me-3' variant="primary" onClick={showPasswordForm}>Change Password</Button>
                </div>
            </div>
        );
    }
    return <div>{content}</div>;

}

export default AccountPage;