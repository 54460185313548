import {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {useAddUserPickMutation, useRemoveUserPickMutation} from "../store";
import _ from "underscore";

function Game({weekNum, game, teamsById, pick, pickedForWeek, handleActiveAccord}) {

    const [showModalAway, setShowModalAway] = useState(false);
    const [showModalHome, setShowModalHome] = useState(false);
    const [isHoverAway, setIsHoverAway] = useState(false);
    const [isHoverHome, setIsHoverHome] = useState(false);
    const [awayPickError, setAwayPickError] = useState("");
    const [homePickError, setHomePickError] = useState("");
    const [addUserPick, addResults] = useAddUserPickMutation();
    const [removeUserPick, {removePickError}] = useRemoveUserPickMutation();
    const [modalTitle, setModalTitle] = useState("");
    const [modalButtonText, setModalButtonText] = useState("");

    function handleOpenModal(team, teamId) {
        if (pick.teamId === teamId) {
            setModalTitle("Remove Pick for Week");
            setModalButtonText("Remove Pick");
        } else {
            setModalTitle("Pick for Week")
            setModalButtonText("Confirm Pick");
        }

        if (team === "away") {
            setShowModalAway(true);
        } else {
            setShowModalHome(true);
        }
    }
    function handleCloseModal(team) {
        if (team === "away") {
            setShowModalAway(false);
        } else {
            setShowModalHome(false);
        }
    }
    const handleMouseEnter = (team) => {
        if (!pickedForWeek) {
            if (team === "away") {
                setIsHoverAway(true);
            } else {
                setIsHoverHome(true);
            }
        }

    };
    const handleMouseLeave = (team) => {
        if (team === "away") {
            setIsHoverAway(false);
        } else {
            setIsHoverHome(false)
        }
    };

    const handleConfirmPick = (team, weekNumber, teamId) => {

        const data = {
            weekNumber,
            teamId
        };

        if (pick.teamId === teamId) {
            removeUserPick(data).unwrap().then(removed => {
                handleCloseModal(team);
            }).catch(rejected => {
                if (team === "away") {
                    setAwayPickError(rejected.data.error);
                } else {
                    setHomePickError(rejected.data.error);
                }
            });

        } else {
            addUserPick(data).unwrap().then(picked => {
                handleCloseModal(team);
            }).catch(rejected => {
                if (team === "away") {
                    setAwayPickError(rejected.data.error);
                } else {
                    setHomePickError(rejected.data.error);
                }
            });
        }
        handleActiveAccord(weekNumber.toString());
    }

    let userPickedHome = false;
    let userPickedAway = false;

    let boxStyleAway;
    let boxStyleHome;

    if (pickedForWeek) {
        if (!_.isEmpty(pick)) {

            if (pick.teamId === game.homeTeam) {
                userPickedHome = true;
                userPickedAway = false;
            }

            if (pick.teamId === game.awayTeam) {
                userPickedAway = true;
                userPickedHome = false;
            }

            boxStyleAway = isHoverAway || userPickedAway ? 'bg-success' : 'opacity-25 disable-click';
            boxStyleHome = isHoverHome || userPickedHome ? 'bg-success' : 'opacity-25 disable-click';
        } else {
            boxStyleAway = isHoverAway ? 'bg-success' : 'opacity-25 disable-click';
            boxStyleHome = isHoverHome ? 'bg-success' : 'opacity-25 disable-click';
        }
    } else {
        if (game.playedStatus !== "UNPLAYED") {
            boxStyleAway = 'opacity-25 disable-click';
            boxStyleHome = 'opacity-25 disable-click';
        } else {
            boxStyleAway = isHoverAway ? 'bg-success' : '';
            boxStyleHome = isHoverHome ? 'bg-success' : '';
        }
    }


    return (
        <>
            <div key={game.awayTeam + weekNum}
                 className={"col-5 col-sm-5 " + boxStyleAway}
                 onMouseEnter={() => handleMouseEnter('away')}
                 onMouseLeave={() => handleMouseLeave('away')}
                 onClick={() => handleOpenModal("away", game.awayTeam)}
            >
                Away: {game.awayTeam}
                <img alt='awayTeam' width='100' height='100'
                     src={`data:image/svg+xml;utf8,${encodeURIComponent(teamsById[game.awayTeam].logoPath)}`}/>
                <div>
                    <span>{game.awayTeamScore}</span>
                </div>
            </div>

            <Modal show={showModalAway} onHide={() => handleCloseModal("away")}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle} {weekNum}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center">
                        {teamsById[game.awayTeam].displayName}
                    </div>
                    <div className="d-flex justify-content-center">
                        <img alt='awayTeam' width='100' height='100'
                             src={`data:image/svg+xml;utf8,${encodeURIComponent(teamsById[game.awayTeam].logoPath)}`}/>
                    </div>
                    <div className="d-flex justify-content-center">
                        <p className="text-danger">{awayPickError}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleCloseModal("away")}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleConfirmPick("away", weekNum, game.awayTeam)}>
                        {modalButtonText}
                    </Button>
                </Modal.Footer>
            </Modal>


            <div className="col-2 col-sm-2 mt-auto mb-auto">
                VS
            </div>


            <div key={game.homeTeam + weekNum}
                 className={"col-5 col-sm-5 " + boxStyleHome}
                 onMouseEnter={() => handleMouseEnter('home')}
                 onMouseLeave={() => handleMouseLeave('home')}
                 onClick={() => handleOpenModal("home", game.homeTeam)}
            >
                Home: {game.homeTeam}
                <img alt='homeTeam' width='100' height='100'
                     src={`data:image/svg+xml;utf8,${encodeURIComponent(teamsById[game.homeTeam].logoPath)}`}/>
                <div>
                    <span>{game.homeTeamScore}</span>
                </div>
            </div>

            <Modal show={showModalHome} onHide={() => handleCloseModal("home")}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle} {weekNum}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center">
                        {teamsById[game.homeTeam].displayName}
                    </div>
                    <div className="d-flex justify-content-center">
                        <img alt='homeTeam' width='100' height='100'
                             src={`data:image/svg+xml;utf8,${encodeURIComponent(teamsById[game.homeTeam].logoPath)}`}/>
                    </div>
                    <div className="d-flex justify-content-center">
                        <p className="text-danger">{homePickError}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleCloseModal("home")}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleConfirmPick("home", weekNum, game.homeTeam)}>
                        {modalButtonText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}

export default Game;
