import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Signup({handleRegister, error}) {

    let content;
    const handleSubmit = (event) => {
        event.preventDefault();

        const userInfo = {
            username:event.target.username.value,

            firstName:event.target.firstName.value,
            lastName:event.target.lastName.value,

            leagueName:event.target.leagueName.value,

            password:event.target.password.value,
            password2:event.target.password2.value
        }
        //console.log("userInfo in register: ", userInfo)
        handleRegister(userInfo);
    }

    if (Object.keys(error).length !== 0) {
       console.error("Signup Error: ", error);
       content = (<div>
           <span className="text-danger">{error.message}</span>
       </div>);
    }

    return (
        <div className='mt-5 d-flex justify-content-center'>

            <Form onSubmit={handleSubmit}>
                {content}
                <h1>Register</h1>
                <Form.Group className="mb-3" controlId="username">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" placeholder="First Name" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Last Name" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="leagueName">
                    <Form.Label>Team Name</Form.Label>
                    <Form.Control type="text" placeholder="Team Name" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="password2">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="Confirm Password" />
                </Form.Group>

                <Button variant="primary" className="mb-3" type="submit">
                    Submit
                </Button>

            </Form>

        </div>
    );
}

export default Signup;