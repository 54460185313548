

function RulesPage() {
    return (
        <div className='mt-5 d-flex justify-content-center'>
            <div className='text-wrap'>
                <h2 className='text-center'>Strikeout League Rules</h2>
                <ol>
                    <li>Each week, you must select one team to LOSE their game
                        <ol>
                            <li>Picks must be made prior to the Start Time of the scheduled game</li>
                        </ol>
                    </li>
                    <li>Each team can only be selected once</li>
                    <li>Strikes
                        <ol>
                            <li>Each wrong selection (the team selected WINS or TIES) results in a strike</li>
                            <li>If a player does not submit a pick, a strike will be issued</li>
                            <li>Once a player receives two strikes, that player is eliminated</li>
                        </ol>
                    </li>
                    <li>
                        Overtime - if more than one player remains at the end of the NFL’s regular season
                        <ol>
                            <li>Team selections reset</li>
                            <li>Play continues until one player remains</li>
                            <li>
                                If more than one player remains at the end of the Super Bowl, the prize pot is split
                                <br/>
                                equally between all remaining players (The pot will not be split based on remaining strikes)
                            </li>
                        </ol>
                    </li>
                    <li>
                        Splitting
                        <ol>
                            <li>At any time, ALL remaining players can agree to a split and the pool will end.</li>
                            <li>To initiate a split, any remaining player may request a split via e-mail at: strikeoutleaguehelp@gmail.com</li>
                        </ol>
                    </li>
                    <li>
                        FAQ
                        <ul>
                            <li>Q: If I chose a team earlier in the season that earned me a strike, can I pick them again?</li>
                            <ul>
                                <li>A: No, you may only select each team once.</li>
                            </ul>

                            <li>Q: If my selected team ties, why do I still receive a strike?</li>
                            <ul>
                                <li>A: The object of the game is to pick a loser; a tie is technically not a loss.</li>
                            </ul>

                            <li>Q: How do I know that Nick Kadlec or Luke Bakken is not cheating?</li>
                            <ul>
                                <li>A: Each week, our picks are logged within the system and the audit tool like everyone else's.
                                <br/>
                                    If we do win (hasn’t happened yet), The log can be reviewed by all who are concerned about our trustworthiness.</li>
                            </ul>

                            <li>Q: If I have both strikes remaining after the Super Bowl, do I get more of the pot than someone with a strike?</li>
                            <ul>
                                <li>A: No, you are either in or out and the pot gets split equally between all remaining players after the Super Bowl</li>
                            </ul>
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
    )
}

export default RulesPage;