import {
    useFetchGamesQuery,
    useFetchTeamsQuery,
    useFetchCurrentWeekNumQuery
} from '../store';
import Skeleton from '../components/Skeleton';
import WeekList from "../components/WeekList";
import Accordion from 'react-bootstrap/Accordion';
import {useState} from "react";
import UserInfo from "../components/UserInfo";
import {useNavigate} from "react-router-dom";

function GamesPage({user}) {
    const navigate = useNavigate();

    const {data:games, isFetching:isFetchingGames, error: errorGames} = useFetchGamesQuery();
    const {data:teams, isFetching:isFetchingTeam, error: errorTeams} = useFetchTeamsQuery();
    const {data:currWeekNum, isFetching:isFetchingCurrWeekNum, error: errorCurrWeekNum} = useFetchCurrentWeekNumQuery();

    const [activeAccord, setActiveAccord] = useState("1");

    let gameRes;
    if (isFetchingGames || isFetchingTeam || isFetchingCurrWeekNum) {
        return <Skeleton className="something" times={3}/>
    } else if (errorGames) {
        navigate("/login");
    } else if(errorTeams || errorCurrWeekNum){
        return <div>Error loading games</div>
    } else {

        let gamesByWeekAndTime = games.reduce(function(map, value)  {
            map[value.weekNumber] = map[value.weekNumber] || [];
            map[value.weekNumber][value.startDateTime] = map[value.weekNumber][value.startDateTime] || [];
            map[value.weekNumber][value.startDateTime].push(value);
            return map;
        }, Object.create(null));

        gameRes = []
        for (const [weekNum, gameByTime] of Object.entries(gamesByWeekAndTime)) {

            gameRes.push(
                <WeekList key={weekNum} weekNum={weekNum}
                          gameByTime={gameByTime}
                          teams={teams}
                          handleActiveAccord={setActiveAccord}
                />
            );
        }

    }

    return (
        <div className='container'>
            <UserInfo user={user}/>
            <Accordion defaultActiveKey={'' + currWeekNum.weekNum}>
                {gameRes}
            </Accordion>
        </div>
    );

}

export default GamesPage;
