import {
    useFetchCurrentWeekNumQuery,
    useFetchUserPickQuery,
    useUserStrikesQuery
} from "../store";
import Skeleton from "./Skeleton";


function UserInfo({user}) {

    const {data:strikes, isFetching:isFetchingStrikes, error: errorStrikes} = useUserStrikesQuery();
    const {data:userPicks, isFetching:isFetchingUserPick, error: errorUserPick} = useFetchUserPickQuery();
    const {data:currentWeek, isFetching: isFetchingCurrentWeek, error: errorCurrentWeek} = useFetchCurrentWeekNumQuery();

    if (isFetchingStrikes || isFetchingUserPick || isFetchingCurrentWeek) {
        return <Skeleton className="something" times={3}/>
    } else if (errorStrikes || errorUserPick || errorCurrentWeek) {
        return <div>Error loading picks</div>
    } else {

        const currentWeekPick = userPicks.filter(pick => pick.weekNumber === currentWeek.weekNum);
        let currentWeekText = currentWeekPick[0]?.teamId || "N/A";
        const previousPicks = userPicks
            .filter(pick => pick.weekNumber !== currentWeek.weekNum && pick.weekNumber < currentWeek.weekNum)
            .map(pick => pick.teamId + " | ");

        return (
            <div className="row justify-content-center flex-fill ms-1 me-1">
                <div className="card text-white bg-secondary mb-3">
                    <h5 className="card-header">
                        Team: {user.leagueName}
                        <p/>
                        {user.firstName} {user.lastName}
                    </h5>

                    <div key={strikes.userId} className="card-body">
                        <p className="card-text">Strikes: {strikes.num_strikes}</p>
                        <p className="card-text">Current Week Pick: {currentWeekText}</p>
                        <p className="card-text">Previous Picks: {previousPicks}</p>
                    </div>
                </div>
            </div>
        );
    }

}

export default UserInfo;