import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";

function Login({handleLogin, error}) {


    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        const user = {
            username: event.target.username.value.toLowerCase(),
            password: event.target.password.value
        }
        handleLogin(user);
    }

    const handleForgotPassword = () => {
        navigate('/forgotPassword')
    }

    let content;
    if (Object.keys(error).length !== 0) {
        content = (<div className="mb-3">
            <span className="text-danger">{error.message}</span>
        </div>);
    }

    return(
        <div>
            <div className='mt-3 d-flex justify-content-center'>
                <Form onSubmit={handleSubmit}>
                    {content}
                    <h1>Login</h1>
                    <Form.Group className="mb-3" controlId="username">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                    </Form.Group>

                    <Button variant="primary" className="mb-3" type="submit">
                        Submit
                    </Button>
                </Form>

            </div>
            <div className='mt-3 d-flex justify-content-center'>
                <a href='#' onClick={handleForgotPassword}>Forgot Password?</a>
            </div>

        </div>
    );

}

export default Login;