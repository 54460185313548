import Games from "./Games";
import Accordion from 'react-bootstrap/Accordion';

const weekday = ["Sun","Mon","Tues","Wed","Thur","Fri","Sat"];
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function sortObj(obj) {
    return Object.keys(obj).sort().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
    }, {});
}

function formatDate(localDate) {
    return weekday[localDate.getDay()]
        + ", "
        + monthNames[localDate.getMonth()]
        + " "
        + localDate.getDate()
        + " "
        + localDate.toLocaleTimeString();
}

function GameTimes({weekNum, gamesContent, teams, handleActiveAccord}) {

    const content = [];

    const sortedDates = sortObj(gamesContent);

    for (const [gameTime, gameArr] of Object.entries(sortedDates)) {
        const localDate = new Date(gameTime);
        const renderedTime = formatDate(localDate);

        content.push(
            <Accordion.Body key={weekNum + localDate}>

                        <div className="row justify-content-center">
                            <div className="col text-center">
                                {renderedTime}
                            </div>
                        </div>
                        <Games weekNum={weekNum}
                               gameArr={gameArr}
                               teams={teams}
                               handleActiveAccord={handleActiveAccord}
                        />

            </Accordion.Body>
        );
    }

    return content;

}

export default GameTimes;