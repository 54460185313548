

function AboutPage() {

    return (
        <div className='container mt-5 d-flex justify-content-center'>
            <div className="row justify-content-md-center">
                <div className="col col-lg-5">
                    <div>
                        <h3>Pay Your Dues</h3>
                        Strikeout league is a one-time $25 dollar buy-in.
                        <br/>
                        <span className='text-muted'>$20 dollars for the prize pot</span>
                        <br/>
                        <span className='text-muted'>$5 dollars to cover server fees, domain name, and administration</span>

                        <p className='mt-3'>Send all dues to Nick Kadlec via Venmo, PayPal, Cash, Coins or Check.</p>
                        <p>
                            <b>Venmo: @NickKadlec</b>
                            <br/>
                            <b>PayPal: Kadlec.nicholas@gmail.com</b>
                        </p>

                    </div>

                    <div className='mt-4'>
                        <h3>Website Issues</h3>
                        The concept of Strikeout league is pretty simple, but this site may have some bugs with how quickly
                        we developed it. If you notice anything, have questions or want to provide suggestions on how to
                        improve the site, please send any requests to Luke Bakken at:
                        <p className='mt-2'><b>strikeoutleaguehelp@gmail.com</b></p>
                    </div>

                    <div className='mt-4'>
                        <h3>Tip Jar</h3>
                        If you're feeling generous and want to buy Nick a beer from all of the work he's done the past years or help
                        out with server costs, we'd greatly appreciate it!
                        <p className='mt-3'><b>Venmo: @NickKadlec</b></p>
                        <p><b>Venmo: @Luke-Bakken-1</b></p>
                        <img alt="The Mayor" className="mt-lg-5" height="400" width="350" src={require('../images/peyton.jpg')} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AboutPage;