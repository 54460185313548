import Login from "../components/Login";
import {useLoginUserMutation} from "../store/apis/usersApi";
import {useEffect, useState} from "react";
import { useNavigate} from "react-router-dom";

function LoginPage({user}) {

    const navigate = useNavigate();
    const [userLogin, results] = useLoginUserMutation();
    const [error, setError] = useState({});

    useEffect(() => {
        if (user) {
            if (user.email) {
                navigate('/currentGames');
            }
        }
    }, []);

    const handleUserLogin = async(user) => {
        try {
            const login = await userLogin(user).unwrap().then();
            navigate("/currentGames");
        } catch (error) {
            setError({message:error.data.error});
        }
    }

    return (
        <div>
            <Login handleLogin={handleUserLogin} error={error}/>
        </div>
    );


}

export default LoginPage;