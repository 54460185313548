import {useNavigate, useSearchParams} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useState} from "react";
import {useSetPasswordMutation} from "../store";
function ResetPasswordPage() {

    const navigate = useNavigate();
    const [uuidParams] = useSearchParams();
    const [successMess, setSuccessMess] = useState({});
    const [errorMess, setErrorMess] = useState({});
    const [setPassword, setPasswordResults] = useSetPasswordMutation();

    const uuid = uuidParams.get('uuid');
    const submitPasswordRest = async (event) => {
        event.preventDefault();
        const username = event.target.username.value;
        const newPassword = event.target.newPassword.value;
        const newPassword2 = event.target.newPassword2.value;

        if (!username) {
            setErrorMess({message:"Email is required"})
        } else if (!newPassword) {
            setErrorMess({message:"New Password is required"})
        } else if (newPassword !== newPassword2) {
            setErrorMess({message:"New Passwords do not match"})
        } else {
            const user = {
                username: username,
                newPassword: newPassword,
                uuid: uuid || ""
            }
            try {
                const res = await setPassword(user).unwrap().then();
                setSuccessMess({message:res.mess});
            } catch (error) {
                setErrorMess({message: error.data.mess})
            }
        }
    }

    const navigateLogin = () => {
        navigate('/login');
    }

    let messContent;
    if (Object.keys(successMess).length !== 0) {
        messContent = (<div className="mb-3">
            <p className="text-success">{successMess.message}</p>
            <p>Proceed to <a className='link-underline-info' onClick={navigateLogin}>Login</a></p>
        </div>);
    } else if (Object.keys(errorMess).length !== 0) {
        messContent = (<div className="mb-3">
            <span className="text-danger">{errorMess.message}</span>
        </div>);
    }

    let content;
    content = (
        <div className='mt-3 d-flex align-items-center flex-column'>
            <div className='p-2'>
                {messContent}
                <div className='mt-1 d-flex flex-row'>
                    <h3>Reset Password</h3>
                </div>
                <Form onSubmit={submitPasswordRest}>
                    <Form.Group className="mb-3" controlId="username">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control type="email" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="newPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control type="password" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="newPassword2">
                        <Form.Label>Confirm New Password:</Form.Label>
                        <Form.Control type="password"/>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Save Changes
                    </Button>
                </Form>
            </div>
        </div>
    );

    return(
        <div>
            {content}
        </div>
    );
}

export default ResetPasswordPage;