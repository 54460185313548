import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

const gamesApi = createApi({

    reducerPath: 'gamesApi', //slice is created behind the scene. Prop on the big state object where all of the API state should be maintained (queries, mutations, provided, subscriptions, configs)
    baseQuery: fetchBaseQuery({ // preconfigured version on the fetch() function that gets passed to RTK
        baseUrl: '/',
        withCredentials: true,
        credentials: 'include',
        fetchFn: async (...args) => {
            return fetch(...args);
        }
    }),
    endpoints(builder) {
        return {
            fetchGames: builder.query({
                providesTags: ['Games'],
                query: () => ({
                    url: '/api/games/schedule/regular/currentWeeks',
                    method: 'GET',
                    withCredentials: true
                }),
            }),
            fetchCurrentWeekNum: builder.query({
                query: () => ({
                    url: '/api/games/schedule/currentNFLWeek',
                    method: 'GET',
                    withCredentials: true
                })
            })
        };
    }
});

export const {
    useFetchGamesQuery,
    useFetchCurrentWeekNumQuery
} = gamesApi;
export { gamesApi }