import '../index.css';
import Game from "./Game";
import {useFetchUserPickQuery} from "../store";
import _ from "underscore";
import Skeleton from "./Skeleton";

function Games({weekNum, gameArr, teams, handleActiveAccord}) {

    const {data:userPick, isFetching:isFetchingUserPick, error: errorUserPick} = useFetchUserPickQuery();

    if (isFetchingUserPick) {
        return <Skeleton className="something" times={3}/>
    } else if (errorUserPick) {
        return <div>Error loading games</div>
    } else {

        let pickedForWeek = false
        const userPickWeek = userPick.filter(pick => {
            return pick.weekNumber === parseInt(weekNum);
        })[0] || {};

        if (!_.isEmpty(userPickWeek)) {
            pickedForWeek = true;
        }

        const teamsById = teams.reduce((acc, obj) => {
            acc[obj.teamId] = obj;
            return acc;
        }, {});

        let gameResult = [];
        for (const game of gameArr) {
            let gamePick = {};
            if (game.homeTeam === userPickWeek.teamId || game.awayTeam === userPickWeek.teamId) {
                gamePick = userPickWeek;
            }

            gameResult.push(
                <div key={game.homeTeam + game.awayTeam} className='card text-center mt-3'>
                    <div className="card-body">
                        <div className="row">
                            <Game weekNum={weekNum}
                                  game={game}
                                  teamsById={teamsById}
                                  pick={gamePick}
                                  pickedForWeek={pickedForWeek}
                                  handleActiveAccord={handleActiveAccord}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        return gameResult;
    }

}

export  default Games;