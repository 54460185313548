import Signup from "../components/Signup";
import {useRegisterUserMutation} from "../store/apis/usersApi";
import {useState} from "react";
import { useNavigate } from 'react-router-dom'

function SignupPage() {

    const navigate = useNavigate();

    const [registerUser, results] = useRegisterUserMutation();
    const [error, setError] = useState({});

    const handleRegisterUser = async(user) => {

        if (user.password !== user.password2) {
            setError({message:"Passwords do not match"});
        } else if (!user.firstName) {
            setError({message:"First Name is Required"});
        } else if (!user.lastName) {
            setError({message:"Last Name is Required"});
        } else if (!user.leagueName) {
            setError({message:"League Name is Required"});
        } else {
            const register = await registerUser(user);
            if (register.data.err) {
                setError(register.data.err);
                //console.log("Error: ", register.data.err);
            } else {
                navigate("/login");
            }
        }

    }

    return <Signup handleRegister={handleRegisterUser} error={error}/>
}

export default SignupPage;