
import { useTable, useBlockLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import React from "react";

function LeaderboardTable({ columns, data }) {
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 150,
            width: 150,
            maxWidth: 350
        }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns,
            data,
            defaultColumn
        },
        useBlockLayout,
        useSticky
    );

    // Workaround as react-table footerGroups doesn't provide the same internal data than headerGroups
    const footerGroups = headerGroups.slice().reverse();

    return (
        <div className='container mt-3 d-flex justify-content-center'>

            <div className="d-flex flex-column" style={{width: 375}}>
                {/*<div className="p-2 bg-primary text-white rounded">*/}
                {/*    <h4>Announcements</h4>*/}
                {/*    <span className="text-wrap">*/}
                {/*        <p>Week 1 has completed! Nick/Luke will be confirming picks this week.</p>*/}

                {/*        <p>Please review below and email us at strikeoutleaguehelp@gmail.com with any issues.</p>*/}
                {/*    </span>*/}
                {/*</div>*/}

                <div className="p-2">
                    <h3>Current Standings</h3>
                </div>

                <div className="mt-3">
                    <div {...getTableProps()} className="table sticky" style={{ width: 375, height: 450 }}>
                        <div className="header">
                            {headerGroups.map((headerGroup) => (
                                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                                    {headerGroup.headers.map((column) => (
                                        <div {...column.getHeaderProps()} className="th">
                                            {column.render('Header')}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>

                        <div {...getTableBodyProps()} className="body">
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <div {...row.getRowProps()} className="tr">
                                        {row.cells.map((cell) => (
                                            <div {...cell.getCellProps()} colSpan='6' className="td">
                                                {cell.render('Cell')}
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>

                        <div className="footer">
                            {footerGroups.map((footerGroup) => (
                                <div {...footerGroup.getHeaderGroupProps()} className="tr">
                                    {footerGroup.headers.map((column) => (
                                        <div {...column.getHeaderProps()} className="td">
                                            {column.render('Footer')}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LeaderboardTable;