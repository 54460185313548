import {useLogoutUserMutation} from "../store";
import { useNavigate } from 'react-router-dom'
import {usersApi} from "../store/apis/usersApi";
import { useDispatch } from 'react-redux';

function Header({user}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [logoutUser, {logoutUserError}] = useLogoutUserMutation();

    const logoutHandler = async() => {
        console.log("Logged out user")
        try {
            const logout = await logoutUser().unwrap().then();
            dispatch(usersApi.util.resetApiState());
            navigate("/login");
        } catch (err) {
            console.log("Login Error: ", err);
            navigate("/login");
        }
    }
    const loginHandler = () => {
        navigate("/login");
    }

    // const signupHandler = () => {
    //     navigate("/signup");
    // }

    const homeHandler = () => {
        navigate("/currentGames");
    }

    const rulesHandler = () => {
        navigate("/rules")
    }

    const aboutHandler = () => {
        navigate("/about")
    }

    const leaderBoardHandler = () => {
        navigate("/leaderboard")
    }

    const accountHandler = () => {
        navigate("/account")
    }

    const adminHandler = () => {
        navigate("/admin")
    }

    let loginLink;
    // let signupLink;
    let adminLink;
    if (user?.email) {
        if (user.email === "bakkenlab@gmail.com" || user.email === "kadlec.nicholas@gmail.com") {
            adminLink = <li><a className="nav-link px-2" onClick={adminHandler}>Admin</a></li>
        }
        loginLink = <button type="button" className="btn btn-outline-primary me-2" onClick={logoutHandler}>Logout</button>
    } else {
        loginLink = <button type="button" className="btn btn-outline-primary me-2" onClick={loginHandler}>Login</button>
        // signupLink = <button type="button" className="btn btn-primary" onClick={signupHandler}>Sign-up</button>
    }

    return (
        <div className="container">
            <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
                <div className="col-md-3 mb-2 mb-md-0">
                    <a className="d-inline-flex link-body-emphasis text-decoration-none" onClick={homeHandler}>
                        <h2>Strikeout League</h2>
                    </a>
                </div>

                <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                    <li><a className="nav-link px-2 " onClick={homeHandler}>Home</a></li>
                    <li><a className="nav-link px-2" onClick={leaderBoardHandler}>Leaderboard</a></li>
                    <li><a className="nav-link px-2" onClick={rulesHandler}>Rules</a></li>
                    <li><a className="nav-link px-2" onClick={aboutHandler}>About</a></li>
                    <li><a className="nav-link px-2" onClick={accountHandler}>Account</a></li>
                    {adminLink}
                </ul>

                <div className="col-md-3 text-end">
                    {loginLink}
                    {/*{signupLink}*/}
                </div>
            </header>
        </div>
    );
}
export default Header;