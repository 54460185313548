import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useForgotPasswordMutation} from "../store";
import {useState} from "react";
function ForgotPasswordPage() {

    const[forgotPassword, forgotPasswordResults] = useForgotPasswordMutation();
    const[forgotSuccess, setForgotSuccess] = useState({});
    const[forgotError, setForgotError] = useState({});

    const handleSubmit = async(event) => {
        event.preventDefault();

        if (event.target.username.value) {
            const user = {
                username: event.target.username.value
            }

            try {
                const forgotSubmitted = await forgotPassword(user).unwrap().then();
                if (forgotSubmitted) {
                    setForgotSuccess({message:forgotSubmitted.mess});
                }
            } catch(error) {
                setForgotError({message:error.data.mess});
            }

        } else {
            setForgotError({message: "Email is required."})
        }
    }

    let content;
    if (Object.keys(forgotSuccess).length !== 0) {
        content = (<div className="mb-3">
            <span className="text-success">{forgotSuccess.message}</span>
        </div>);
    } else if (Object.keys(forgotError).length !== 0) {
        content = (<div className="mb-3">
            <span className="text-danger">{forgotError.message}</span>
        </div>);
    }

    return (
        <div className='mt-3 d-flex justify-content-center'>
            <Form onSubmit={handleSubmit}>
                {content}
                <h3>Forgot Password</h3>
                <Form.Group className="mb-3 mt-3" controlId="username">
                    <Form.Label>Email address:</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>

                <Button variant="primary" className="mb-3" type="submit">
                    Submit
                </Button>
            </Form>
        </div>
    )

}

export default ForgotPasswordPage;