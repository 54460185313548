import {useFetchUsersQuery, useUserHasPaidMutation} from "../store";
import Button from "react-bootstrap/Button";
import {Card} from "react-bootstrap";
function AdminPage() {

    const {data:users, isFetching:isFetchingUsers, error:errorUsers} = useFetchUsersQuery();
    const [userPaid, userPaidResults] = useUserHasPaidMutation();

    const handleHasPaid = async(email) => {
        const user = {
            email
        };
        await userPaid(user);
    }

    let userList = [];
    if (isFetchingUsers) {
        return <div>Fetching users</div>
    } else if (errorUsers) {
        return <div>Error getting users</div>
    } else {

        let button;
        for (const user of users) {

            if (user.hasPaid) {
                button = <Button disabled>Yes</Button>
            } else {
                button = <Button className='btn-danger' onClick={() => handleHasPaid(user.email)}>No</Button>
            }

            userList.push(
                <Card key={user.email} className='mb-3'>
                    <Card.Body>
                        <div>
                            <p>First Name: {user.firstName}</p>
                            <p>Last Name: {user.lastName}</p>
                            <p>Team Name: {user.leagueName}</p>
                            <p>Email: {user.email}</p>
                        </div>
                        <div>
                            <p>Has Paid?</p>
                            {button}
                        </div>
                    </Card.Body>
                </Card>
            );
        }
    }

    return (
        <div className='mt-3 d-flex align-items-center flex-column'>
            <div className='p-2'>
                <div className='mt-3 d-flex flex-row'>
                    <h3>Admin</h3>
                </div>
                <div className='mt-3 d-flex flex-column'>
                    {userList}
                </div>
            </div>
        </div>
    );
}

export default AdminPage;