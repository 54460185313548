import GameTimes from "./GameTimes";
import Accordion from 'react-bootstrap/Accordion';
function WeekList({weekNum, gameByTime, teams, handleActiveAccord}) {

    return (

        <Accordion.Item eventKey={weekNum}>
            <Accordion.Header>
                    <h5 className="mb-0">
                        Week {weekNum}
                    </h5>
            </Accordion.Header>
            <GameTimes weekNum={weekNum}
                       gamesContent={gameByTime}
                       teams={teams}
                       handleActiveAccord={handleActiveAccord}
            />
        </Accordion.Item>
    );
}

export default WeekList;