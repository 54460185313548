import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

const teamsApi = createApi({

    reducerPath: 'teamsApi', //slice is created behind the scene. Prop on the big state object where all of the API state should be maintained (queries, mutations, provided, subscriptions, configs)
    baseQuery: fetchBaseQuery({ // preconfigured version on the fetch() function that gets passed to RTK
        baseUrl: '/',
        withCredentials: true,
        credentials: 'include',
        fetchFn: async (...args) => {
            return fetch(...args);
        }
    }),
    endpoints(builder) {
        return {
            fetchTeams: builder.query({
                providesTags: ['Teams'],
                query: () => ({
                    url: '/api/teams/',
                    method: 'GET'
                }),
            })
        };
    }
});

export const {useFetchTeamsQuery} = teamsApi;
export { teamsApi }