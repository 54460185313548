import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { usersApi } from './apis/usersApi';
import { gamesApi } from "./apis/gamesApi";
import { teamsApi } from "./apis/teamsApi";

export const store = configureStore({
  reducer: {
      [usersApi.reducerPath]: usersApi.reducer,
      [gamesApi.reducerPath]: gamesApi.reducer,
      [teamsApi.reducerPath]: teamsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
    .concat(usersApi.middleware)
    .concat(teamsApi.middleware)
    .concat(gamesApi.middleware);
  }
});

setupListeners(store.dispatch);

export {
    useFetchUsersQuery,
    useUserStrikesQuery,
    useFetchSingleUserQuery,
    useFetchUserPickQuery,
    useFetchAllUserPicksQuery,
    useFetchAllUserStrikesQuery,
    useUserHasPaidMutation,
    useAddUserMutation,
    useAddUserPickMutation,
    useEditUserMutation,
    useRemoveUserMutation,
    useRemoveUserPickMutation,
    useRegisterUserMutation,
    useLoginUserMutation,
    useLogoutUserMutation,
    useChangePasswordMutation,
    useForgotPasswordMutation,
    useSetPasswordMutation
} from './apis/usersApi'

export {
    useFetchGamesQuery,
    useFetchCurrentWeekNumQuery
} from './apis/gamesApi'

export {
    useFetchTeamsQuery
} from './apis/teamsApi'